import Swiper from 'swiper';

export function mouvers(){
  const mouverSlides = document.getElementById('mouverSlides')
  if(mouverSlides){
    let plam = {
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 4,
      centeredSlides: false,
      speed: 400,
      spaceBetween: 20,
      loop: false,
      breakpoints: {
        768: {
          slidesPerView: 1,
          spaceBetween: 30,
          centeredSlides: false
        }
      }
    };
    new Swiper( mouverSlides, plam);
  }


  const videoLocalNavigation = document.getElementById('videoLocalNavigation')
  if(videoLocalNavigation){
    const child = videoLocalNavigation.querySelectorAll('a')
    const list = document.querySelectorAll('.videotoggle')
    child.forEach((el)=>{
      el.addEventListener('click', function() {
        child.forEach((elem)=>{
          elem.classList.remove('active')
        })
        list.forEach((elem)=>{
          elem.style.display = 'none'
        })
        this.classList.add('active')
        const dataset = this.dataset.link
        document.getElementById(dataset).style.display = 'block'
      })
    })
  }
}