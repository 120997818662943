export function vues(){
    const v = new Vue({
        el: '#site-header',
        data: {
            flag: false,
            product: false,
            catalog: false,
            active: false,
        },
        mounted: function(){
            this.active = true;
        }
    });
}